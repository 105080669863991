body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-text-size{
  font-size: 20px;
}

.cer-logo{
  width: 50%;
  margin-top: 2%;
}

.bg-yellow{
  background-color: rgb(254 183 46);
}

.white-text{
  color: #fff;
}

.yellow-text{
  color: rgb(254 183 46);
}

.bold-text{
  font-weight: bolder;
}

.white-text-size{
  font-weight: bold;
  font-size: 15px;
  text-align: justify;
}

.yellow-text-size{
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  font-style: italic;
}

.empty-space{
  padding: 30%;
}

.yellow-box{
  height: 180px;
}

.white-box{
  height: 80px;
}

.card{
  border: none !important;
}

.card-body{
  padding: 0% !important;
}

.card-img-top{
  width: 40%;
  margin: 0 35px;
}

.jumbotron{
  padding-top: 20px !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}